html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol {
  padding: 0;
}

li {
  list-style: none;
}
