.card {
  min-height: 70vh;
  padding: 5vh;

  @media (min-width: 750px) {
    padding-left: 10vw;
    margin-right: 10vw;
  }

  @media (min-width: 1500px) {
    padding-left: 20vw;
    margin-right: 20vw;
  }

  &:nth-child(2n) {
    background: #dcdcdc;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 1px;
      left: 0;
    }

    &::after {
      top: auto;
      bottom: 1px;
    }
  }
}

.card__image {
  background-color: #fff;
  padding: 10px;
  box-shadow: 3px 10px 15px rgba(0, 0, 0, 0.25);
  font-family: monospace;
  font-size: 14px;
  margin: 0 auto 40px;
  max-width: 400px;

  @media (min-width: 650px) {
    float: right;
    max-width: 250px;
    margin-left: 40px;
  }

  @media (min-width: 1000px) {
    padding: 15px;
    max-width: 300px;
    transform: rotate(3deg);
  }

  img {
    display: block;
    width: 100%;
  }

  p {
    margin: 10px 0 0 0;
  }
}
